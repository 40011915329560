<template>
   <v-dialog
    v-model="show"
    persistent
    max-width="500px"
    scrollable
    content-class="content-scroll"
  >
    <v-card tile>
      <v-card-title class="d-flex justify-space-between align-start blue-grey lighten-5 pr-1">
        <div class="d-flex flex-column">
          <span class="display-1 primary--text font-weight-medium">
            <!-- <v-icon left color="primary" size="20">mdi-map-marker</v-icon> -->
            Información
          </span>
          <v-list
            subheader
            two-line
            flat
            class="pt-0 transparent"
          >
            <v-list-item class="ml-0 pl-0">
              <v-list-item-icon class="mb-2 mr-2">
                <v-icon  size="32">mdi-account-tie</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="py-0">
                <v-list-item-subtitle>
                  <span class="font-weight-regular caption">Cliente</span>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <span class="font-weight-medium">{{client.name}} ({{client.co_cli}})</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="ml-0 pl-0">
              <v-list-item-icon class="mb-2 mr-2">
                <v-icon  size="32">mdi-account-supervisor-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="py-0">
                <v-list-item-subtitle>
                  <span class="font-weight-regular caption">Vendedor</span>
                </v-list-item-subtitle>
                <v-list-item-title>
                  <span class="font-weight-medium">{{client.ven}} ({{client.co_ven}})</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
        </v-list>
        </div>
        <v-btn icon @click="show = false" small class="mr-2">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-subtitle class="blue-grey lighten-5 pb-1">
        <span class="primary--text text--lighten-1 pt-2 font-weight-medium">Lineas</span>
      </v-card-subtitle>
      <v-card-text class="log-details content-scroll px-0 pb-1">
        <v-data-iterator
          :items="lines"
          hide-default-footer
          disable-pagination
          class="mb-0 pb-0"
        >
          <template v-slot:default="props">
            <v-list
              subheader
              two-line
              flat
              class="pt-0"
            >
              <template v-for="(client,i) in props.items">
                <v-list-item :class="(i+1)%2 === 0 && 'grey lighten-5'">
                  <v-list-item-content>
                    <v-row>
                      <v-col cols="6">
                        <v-icon size="20" left color="info">mdi-package-variant-closed</v-icon>
                       <span class="font-weight-bold">{{ client.nombre }}</span>
                      </v-col>
                      <v-col cols="2"
                        class="text-center px-0"
                        :class="{'font-weight-medium': client.cantidad > 0}"
                      >{{client.cantidad}}</v-col>
                      <v-col cols="4" class="text-right font-italic">{{client.fecha | smartDate}}</v-col>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="i < props.items.length - 1"/>
              </template>
            </v-list>
          </template>
          <template v-slot:no-data>
          <div class="text-center pa-4">
            <v-icon color="blue-grey" left>mdi-shield-alert-outline</v-icon>
            <span class="blue-grey--text font-italic">No se encontraron Lineas</span>
          </div>
          </template>
        </v-data-iterator>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'ModalClienteMap',
  props:{
    value: Boolean,
    client: {
      type: Object,
      default: () => ({})
    }
  },
  data(){
    return {
      show: this.value,
      download: false
    }
  },
  watch: {
    value(val, oldVal){
      if(val === oldVal) return;

      this.show = val;
    },
    show(val, oldVal){
      if(val === oldVal) return;

      this.$emit('input', val);
    }
  },
  computed: {
    lines () {
      return this.client?.lineas?.length > 0 ? this.client?.lineas : []
    }
  },
  methods:{
  }
}
</script>
